import React, { useState, useEffect, useRef } from "react";
import MonthlyPage1 from "./MonthlyPage1";
import MonthlyPage2 from "./MonthlyPage2";
import MonthlyPage3 from "./MonthlyPage3";
import MonthlyPage4 from "./MonthlyPage4";
import MonthlyPage5 from "./MonthlyPage5";
import ReportAPIService from "../api/reports.api.js";
import HelperFunctions from "../HelperFunctions/HelperFunctions.js";
import FormatNumbersFunctions from "../HelperFunctions/FormatNumbersFunctions.js";

export default function MonthlyReport() {
  const urlList = window.location.href.split("//").pop().split("/");
  const companyName = urlList[1];
  const sendTime = urlList[2];
  const campaignId = urlList[3];
  const fileName = `${sendTime}_${companyName}`;
  const monthlyDate = new Date();
  const [pageNum, setPageNum] = useState(1);
  const [campaign, setCampaign] = useState();
  const [report, setReport] = useState();
  const [links, setLinks] = useState();
  const [previewHtml, setPreviewHtml] = useState("");
  const [topLinks, setTopLinks] = useState([]); //{ url: "", clicks: 0 }
  const [savedCampaigns, setSavedCampaigns] = useState([]);
  const [savedReports, setSavedReports] = useState([]);
  const [isUpdateClicked, setIsUpdateClicked] = useState(false);

  const previewRefPage1 = useRef(null);
  const previewRefPage3 = useRef(null);
  const phoneImageRef = useRef(null);

  useEffect(() => {
    async function getContentFromSavedFile(savedFileNames) {
      var tempCampaigns = [];
      var tempReports = [];

      let campaignDates = "";
      let thisCampaignDate = "";

      for (const element of savedFileNames) {
        if (companyName.includes("workshop-7") || companyName.includes("onpoint")) {
          let elementDateList = element.split("_")[0].split("-");
          let elementDateString = `${elementDateList[0]}-${elementDateList[1]}-${elementDateList[2]} ${elementDateList[3]}:${elementDateList[4]}`;
          let fileNameDateList = fileName.split("_")[0].split("-");
          let fileNameDateString = `${fileNameDateList[0]}-${fileNameDateList[1]}-${fileNameDateList[2]} ${fileNameDateList[3]}:${fileNameDateList[4]}`;
          campaignDates = new Date(elementDateString);
          thisCampaignDate = new Date(fileNameDateString);
          console.log(elementDateString, fileNameDateString)
        } else {
          campaignDates = new Date(element.split("_")[0]);
          thisCampaignDate = new Date(fileName.split("_")[0]);
        }
        if (campaignDates < thisCampaignDate) {
          const [errorReturnedData, returnedData] =
            await ReportAPIService.GetSavedFileContent(companyName, element);
          if (errorReturnedData) {
            console.error("Error fetching campaigns:", errorReturnedData);
          }
          if (returnedData[0] && returnedData[1]) {
            tempCampaigns = tempCampaigns.concat(returnedData[0]);
            tempReports = tempReports.concat(returnedData[1]);
          }
        } else if (
              campaignDates.getDate() === thisCampaignDate.getDate() &&
              campaignDates.getMonth() + 1 === thisCampaignDate.getMonth() + 1 &&
          campaignDates.getFullYear() === thisCampaignDate.getFullYear()
        ) {
          if (companyName.includes("workshop-7") || companyName.includes("onpoint")) {
            if (
              campaignDates.getHours() === thisCampaignDate.getHours() &&
              campaignDates.getMinutes() === thisCampaignDate.getMinutes()
            ) {
              const [errorReturnedData, returnedData] =
              await ReportAPIService.GetSavedFileContent(companyName, element);
              if (errorReturnedData) {
                console.error("Error fetching campaigns:", errorReturnedData);
              }
                if (
                  returnedData[0] &&
                  returnedData[1] &&
                  returnedData[2] &&
                  returnedData[3]
                ) {
                setCampaign(returnedData[0]);
                setReport(returnedData[1]);
                setLinks(returnedData[2]);
                setPreviewHtml(returnedData[3]);
              }
            }
          }
          else {
            const [errorReturnedData, returnedData] =
            await ReportAPIService.GetSavedFileContent(companyName, element);
            if (errorReturnedData) {
              console.error("Error fetching campaigns:", errorReturnedData);
            }
            if (
              returnedData[0] &&
              returnedData[1] &&
              returnedData[2] &&
              returnedData[3]
            ) {
            setCampaign(returnedData[0]);
            setReport(returnedData[1]);
            setLinks(returnedData[2]);
            setPreviewHtml(returnedData[3]);
          }
          }
        }
      }
      tempCampaigns.sort((a, b) => new Date(b.sendTime) - new Date(a.sendTime));
      tempReports.sort((a, b) => new Date(b.sendTime) - new Date(a.sendTime));
      setSavedCampaigns(tempCampaigns);
      setSavedReports(tempReports);
    }

    async function getSavedFiles() {
      const [errorReturnedData, returnedData] =
        await ReportAPIService.GetSavedFileNames(companyName);
      if (errorReturnedData) {
        console.error("Error fetching campaigns:", errorReturnedData);
      }
      if (returnedData) {
        returnedData.forEach((element, index, array) => {
          array[index] = element.split("\\").pop();
        });
      }
      return returnedData;
    }

    function combineClicksOfSameLinks(links) {
      var linkList = []; //{ url: "", clicks: 0 }

      links.forEach((firstLink) => {
        let linkURL = firstLink.url.split("?utm")[0];
        let totalClicks = 0;
        links.forEach((secondLink) => {
          let secondLinkURL = secondLink.url.split("?utm")[0];
          if (secondLinkURL === linkURL) {
            totalClicks += secondLink.totalClicks;
          }
        });
        linkList = linkList.concat({
          url: firstLink.url,
          clicks: totalClicks,
        });
      });
      reduceLinksList(linkList);
    }

    function reduceLinksList(linkList) {
      var uniqueUrls = {};
      var uniqueList = [];

      linkList.sort(function (a, b) {
        return b.clicks - a.clicks;
      });

      linkList.forEach(function (item) {
        var url = item.url.split("?utm")[0];
        if (!uniqueUrls[url]) {
          uniqueList.push(item);
          uniqueUrls[url] = true;
        }
      });
      setTopLinks(uniqueList);
    }

    async function loadSavedData() {
      const returnedData = await getSavedFiles();
      if (campaign === null || campaign === undefined) {
        await getContentFromSavedFile(returnedData);
      }
    }

    loadSavedData();

    if (links) {
      combineClicksOfSameLinks(links);
    }
  }, [
    campaignId,
    campaign,
    companyName,
    fileName,
    links,
    pageNum,
    previewHtml,
    report,
    sendTime,
  ]);

  useEffect(() => {
    HelperFunctions.getColour(companyName, pageNum, 5);
  });

  function nextClicked() {
    setPageNum(pageNum + 1);
  }

  function prevClicked() {
    setPageNum(pageNum - 1);
  }

  function backClicked() {
    window.location.href = `/${companyName}/reports-list`;
  }

  function getLast6Campaigns() {
    var campaignsList = [];
    campaignsList = [campaign];
    campaignsList = campaignsList.concat(savedCampaigns[0]);
    campaignsList = campaignsList.concat(savedCampaigns[1]);
    campaignsList = campaignsList.concat(savedCampaigns[2]);
    campaignsList = campaignsList.concat(savedCampaigns[3]);
    campaignsList = campaignsList.concat(savedCampaigns[4]);
    return campaignsList;
  }

  function getLast6Reports() {
    var reportsList = [];
    reportsList = [report];
    reportsList = reportsList.concat(savedReports[0]);
    reportsList = reportsList.concat(savedReports[1]);
    reportsList = reportsList.concat(savedReports[2]);
    reportsList = reportsList.concat(savedReports[3]);
    reportsList = reportsList.concat(savedReports[4]);
    return reportsList;
  }

  async function updateData() {
    if (
      window.confirm(
        "Are you sure you want to update this report?\nThis will overwrite the saved data."
      )
    ) {
      setIsUpdateClicked(true);
      let [updatedCampaign, updatedReport, updatedLinks, updatedPreview] =
        await HelperFunctions.LoadNewData(campaignId);
      let data = [updatedCampaign, updatedReport, updatedLinks, updatedPreview];
      let url = "";
      if (companyName.includes("workshop-7")) {
        url = `https://hitsendreporting.s05.system7.co.nz/api/api/report/save/${companyName}/${FormatNumbersFunctions.formatDateWithTime(
          new Date(updatedCampaign.sendTime)
        )}`;
      } else {
        url = `https://hitsendreporting.s05.system7.co.nz/api/api/report/save/${companyName}/${FormatNumbersFunctions.formatDateForFileName(
          new Date(updatedCampaign.sendTime)
        )}`;
      }
      const [error, result] = await ReportAPIService.SaveData(url, data);
      if (error) {
        console.error("Error fetching preview:", error);
      }
      if (result) {
        setIsUpdateClicked(false);
        window.location.reload();
      }
    }
  }

  function choosePage() {
    if (pageNum === 1) {
      return (
        <MonthlyPage1
          campaign={campaign}
          monthlyDate={monthlyDate}
          companyName={companyName}
          previewHtml={previewHtml}
          previewRefPage1={previewRefPage1}
          phoneImageRef={phoneImageRef}
        />
      );
    } else if (pageNum === 2) {
      return (
        <MonthlyPage2
          campaign={campaign}
          report={report}
          lastCampaign={savedCampaigns[0]}
          lastReport={savedReports[0]}
          companyName={companyName}
        />
      );
    } else if (pageNum === 3) {
      return (
        <MonthlyPage3
          companyName={companyName}
          campaign={campaign}
          links={links}
          report={report}
          previewHtml={previewHtml}
          lastCampaign={savedCampaigns[0]}
          lastReport={savedReports[0]}
          topLinks={topLinks}
          previewRefPage3={previewRefPage3}
          pageNum={pageNum}
        />
      );
    } else if (pageNum === 4) {
      return <MonthlyPage4 campaigns={getLast6Campaigns()} />;
    } else if (pageNum === 5) {
      return (
        <MonthlyPage5
          campaigns={getLast6Campaigns()}
          reports={getLast6Reports()}
        />
      );
    }
    return null;
  }

  function showPages() {
    return (
      <>
        <div className="monthly-report" id="monthly-report">
          <div
            className={`monthly-page-${pageNum}`}
            id={`monthly-page-${pageNum}`}
          >
            {choosePage()}
            {pageNum !== 0 && pageNum !== 1 ? (
              <button
                className="btn-general btn-prev"
                onClick={() => prevClicked()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2rem"
                  viewBox="0 0 320 512"
                >
                  <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                </svg>
              </button>
            ) : null}
            {pageNum !== 5 ? (
              <button
                className="btn-general btn-next"
                onClick={() => nextClicked()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2rem"
                  viewBox="0 0 320 512"
                >
                  <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              </button>
            ) : null}
            <button
              className="btn-general btn-export"
              onClick={() => {
                setPageNum(0);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2rem"
                viewBox="0 0 512 512"
              >
                <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
              </svg>
            </button>
          </div>
        </div>
      </>
    );
  }

  function main() {
    console.log(
      campaign ,
      report ,
      previewHtml ,
      savedCampaigns ,
      savedReports ,
      links ,
      topLinks ,
      monthlyDate
    )
    if (
      campaign &&
      report &&
      previewHtml &&
      savedCampaigns &&
      savedReports &&
      links &&
      topLinks &&
      monthlyDate
    ) {
      if (pageNum === 0) {
        return (
          <>
            <div className="monthly-page-0" id="monthly-page-0">
              <button
                className="btn-general btn-next"
                onClick={() => nextClicked()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2rem"
                  viewBox="0 0 320 512"
                >
                  <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              </button>
              <button
                className="btn-general btn-export"
                onClick={() => {
                  // HelperFunctions.generatePDF();
                  HelperFunctions.generateImages(companyName);
                  HelperFunctions.generateIframe(companyName, previewHtml);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2rem"
                  viewBox="0 0 512 512"
                >
                  <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                </svg>
              </button>

              <div className="monthly-pages" id="monthly-pages">
                <div className="monthly-report" id="monthly-report">
                  <div
                    className="monthly-page-1 component-to-capture"
                    id="monthly-page-1"
                  >
                    <MonthlyPage1
                      campaign={campaign}
                      monthlyDate={monthlyDate}
                      companyName={companyName}
                      previewHtml={previewHtml}
                      previewRefPage1={previewRefPage1}
                      phoneImageRef={phoneImageRef}
                    />
                  </div>
                </div>
                <div className="monthly-report" id="monthly-report">
                  <div
                    className="monthly-page-2 component-to-capture"
                    id="monthly-page-2"
                  >
                    <MonthlyPage2
                      campaign={campaign}
                      report={report}
                      lastCampaign={savedCampaigns[0]}
                      lastReport={savedReports[0]}
                      companyName={companyName}
                    />
                  </div>
                </div>
                <div className="monthly-report" id="monthly-report">
                  <div
                    className="monthly-page-3 component-to-capture"
                    id="monthly-page-3"
                  >
                    <MonthlyPage3
                      companyName={companyName}
                      campaign={campaign}
                      links={links}
                      report={report}
                      previewHtml={previewHtml}
                      lastCampaign={savedCampaigns[0]}
                      lastReport={savedReports[0]}
                      topLinks={topLinks}
                      previewRefPage3={previewRefPage3}
                      pageNum={pageNum}
                    />
                  </div>
                </div>
                <div className="monthly-report" id="monthly-report">
                  <div
                    className="monthly-page-4 component-to-capture"
                    id="monthly-page-4"
                  >
                    <MonthlyPage4 campaigns={getLast6Campaigns()} />
                  </div>
                </div>
                <div className="monthly-report" id="monthly-report">
                  <div
                    className="monthly-page-5 component-to-capture"
                    id="monthly-page-5"
                  >
                    <MonthlyPage5
                      campaigns={getLast6Campaigns()}
                      reports={getLast6Reports()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      } else if (pageNum > 0) {
        return showPages();
      } else {
        return (
          <>
            <div className="monthly-report" id="monthly-report">
              Loading...
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <div className="monthly-report" id="monthly-report">
            Loading...
          </div>
        </>
      );
    }
  }

  return (
    <>
      <button
        className="btn-general btn-back"
        onClick={() => {
          backClicked();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2rem"
          viewBox="0 0 448 512"
        >
          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
        </svg>
      </button>
      <button
        className="btn-general btn-update"
        onClick={() => {
          updateData();
        }}
      >
        {isUpdateClicked ? "Updating..." : "Update"}
      </button>
      {main()}
    </>
  );
}
