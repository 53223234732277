import "./scss/App.scss";
import Home from "./Home";
import React from "react";

function App() {
  return (
    <>
      <div className="App">
        <div className="container">
          <Home />
        </div>
      </div>
    </>
  );
}
export default App;
