import React, { useState, useEffect, useRef } from "react";
import MonthlyPage1 from "../MonthlyReportBase/MonthlyPage1";
import MonthlyPage1Cloudbase from "./MonthlyPage1Cloudbase";
import MonthlyPage2Cloudbase from "./MonthlyPage2Cloudbase";
import MonthlyPage4Cloudbase from "./MonthlyPage4Cloudbase";
import MonthlyPage5Cloudbase from "./MonthlyPage5Cloudbase";
import ReportAPIService from "../api/reports.api.js";
import HelperFunctions from "../HelperFunctions/HelperFunctions.js";

export default function MonthlyReport() {
  const urlList = window.location.href.split("//").pop().split("/");
  const companyName = urlList[1];
  const sendTime = urlList[2];
  const campaignId = urlList[3];
  const fileName = `${sendTime}-${campaignId}_${companyName}`;
  const monthlyDate = new Date();
  const [pageNum, setPageNum] = useState(1);
  const [saved, setSaved] = useState(false);
  const [campaigns, setCampaigns] = useState();
  const [reports, setReports] = useState();
  const [linksList, setLinksList] = useState([]);
  const [campaign, setCampaign] = useState();
  const [report, setReport] = useState();
  const [links, setLinks] = useState([]);
  const [previewHtml, setPreviewHtml] = useState("");
  const [topLinks, setTopLinks] = useState([]); //{ url: "", clicks: 0 }
  const [resendTopLinks, setResendTopLinks] = useState([]); //{ url: "", clicks: 0 }
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [savedCampaigns, setSavedCampaigns] = useState([]);
  const [savedReports, setSavedReports] = useState([]);
  const [isUpdateClicked, setIsUpdateClicked] = useState(false);
  const [resendIndex, setResendIndex] = useState(-1);

  const previewRefPage1 = useRef(null);
  const previewRefPage1Cloudbase = useRef(null);
  const myDivRef = useRef(null);

  useEffect(() => {
    async function getContentFromSavedFile(returnedData) {
      var tempSavedCampaigns = [];
      var tempSavedReports = [];
      var tempCampaigns = [];
      var tempReports = [];
      var tempLinksList = [];

      for (const element of returnedData) {
        var tempLinks = [];
        const datePattern = /^\d{4}-\d{2}-\d{2}/;
        var campaignDates = new Date(
          element.split("_")[0].match(datePattern)[0]
        );
        var thisCampaignDate = new Date(sendTime);
        if (campaignDates < thisCampaignDate) {
          const [errorReturnedData, returnedData] =
            await ReportAPIService.GetSavedFileContent(companyName, element);
          if (errorReturnedData) {
            console.error("Error fetching campaigns:", errorReturnedData);
          }
          if (returnedData[0] && returnedData[1] && returnedData[2]) {
            tempCampaigns = tempCampaigns.concat(returnedData[0]);
            tempReports = tempReports.concat(returnedData[1]);
            tempLinks = tempLinks.concat(returnedData[2]);
            tempSavedCampaigns = tempSavedCampaigns.concat(returnedData[0]);
            tempSavedReports = tempSavedReports.concat(returnedData[1]);
          }
        } else if (
          campaignDates.getMonth() + 1 === thisCampaignDate.getMonth() + 1 &&
          campaignDates.getFullYear() === thisCampaignDate.getFullYear()
        ) {
          const [errorReturnedData, returnedData] =
            await ReportAPIService.GetSavedFileContent(companyName, element);
          if (errorReturnedData) {
            console.error("Error fetching campaigns:", errorReturnedData);
          }
          if (
            returnedData[0] &&
            returnedData[1] &&
            returnedData[2] &&
            returnedData[3]
          ) {
            tempCampaigns = tempCampaigns.concat(returnedData[0]);
            tempReports = tempReports.concat(returnedData[1]);
            tempLinks = tempLinks.concat(returnedData[2]);
            setPreviewHtml(returnedData[3]);
          }
        }
        if (tempLinks.length > 0) {
          tempLinksList.push(tempLinks);
        }
      }
      tempSavedCampaigns.sort(
        (a, b) => new Date(b.sendTime) - new Date(a.sendTime)
      );
      tempSavedReports.sort(
        (a, b) => new Date(b.sendTime) - new Date(a.sendTime)
      );
      setSavedCampaigns(tempSavedCampaigns);
      setSavedReports(tempSavedReports);

      setCampaigns(tempCampaigns);
      setReports(tempReports);
      setLinksList(tempLinksList);

      if (years.length === 0 || months.length === 0) {
        let thisCampaignDate = new Date(sendTime);
        let newMonthsList = [thisCampaignDate.getMonth() + 1];
        let newYearsList = [thisCampaignDate.getFullYear()];
        for (let i = 0; i < tempSavedCampaigns.length; i++) {
          if (
            !newMonthsList.includes(
              new Date(tempSavedCampaigns[i].sendTime).getMonth() + 1
            ) &&
            newMonthsList.length < 6 &&
            new Date(tempSavedCampaigns[i].sendTime) <=
              new Date(tempSavedCampaigns[1].sendTime) &&
            new Date(tempSavedCampaigns[i].sendTime).getFullYear() > 2000
          ) {
            newMonthsList = newMonthsList.concat(
              new Date(tempSavedCampaigns[i].sendTime).getMonth() + 1
            );
            newYearsList = newYearsList.concat(
              new Date(tempSavedCampaigns[i].sendTime).getFullYear()
            );
          }
        }
        setYears(newYearsList);
        setMonths(newMonthsList);

        if (campaign === null || campaign === undefined) {
          for (let i = 0; i < tempCampaigns.length; i++) {
            if (tempCampaigns[i].id === campaignId) {
              setCampaign(tempCampaigns[i]);
              setReport(tempReports[i]);
              setLinks(tempLinksList[i]);
              break;
            }
          }
        }
      }
    }

    async function getSavedFiles() {
      const [errorReturnedData, returnedData] =
        await ReportAPIService.GetSavedFileNames(companyName);
      if (errorReturnedData) {
        console.error("Error fetching campaigns:", errorReturnedData);
      }
      if (returnedData) {
        returnedData.forEach((element, index, array) => {
          array[index] = element.split("\\").pop();
        });
      }
      return returnedData;
    }

    function combineClicksOfSameLinks(links, isResend) {
      var linkList = []; //{ url: "", clicks: 0 }

      links.forEach((firstLink) => {
        let linkURL = firstLink.url.split("utm")[0];
        let totalClicks = 0;
        links.forEach((secondLink) => {
          let secondLinkURL = secondLink.url.split("utm")[0];
          if (secondLinkURL === linkURL) {
            totalClicks += secondLink.totalClicks;
          }
        });
        linkList = linkList.concat({
          url: firstLink.url,
          clicks: totalClicks,
        });
      });
      reduceLinksList(linkList, isResend);
    }

    function reduceLinksList(linkList, isResend) {
      var uniqueUrls = {};
      var uniqueList = [];

      linkList.sort(function (a, b) {
        return b.clicks - a.clicks;
      });

      linkList.forEach(function (item) {
        var url = item.url.split("?utm")[0];
        if (!uniqueUrls[url]) {
          uniqueList.push(item);
          uniqueUrls[url] = true;
        }
      });
      if (!isResend) {
        setTopLinks(uniqueList);
      } else {
        setResendTopLinks(uniqueList);
      }
    }

    async function loadSavedData() {
      const returnedData = await getSavedFiles();
      if (returnedData.includes(fileName + ".json")) {
        setSaved(true);
      }
      if (campaign === null || campaign === undefined) {
        await getContentFromSavedFile(returnedData);
      }
    }

    loadSavedData();

    if (links.length > 0 && linksList.length > 0 && topLinks.length === 0) {
      combineClicksOfSameLinks(links, 0);
      let tempResendIndex = campaigns.findIndex(
        (c) =>
          c.settings.title.toLowerCase().includes("(didn't open initial)") &&
          new Date(c.sendTime).getMonth() + 1 ===
            new Date(campaign.sendTime).getMonth() + 1 &&
          new Date(c.sendTime).getFullYear() ===
            new Date(campaign.sendTime).getFullYear()
      );
      setResendIndex(tempResendIndex);
      combineClicksOfSameLinks(linksList[tempResendIndex], 1);
    }

    return () => {};
  }, [
    campaignId,
    campaign,
    companyName,
    fileName,
    links,
    pageNum,
    previewHtml,
    report,
    saved,
    months,
    linksList,
    sendTime,
    campaigns,
    reports,
    years,
    topLinks,
  ]);

  useEffect(() => {
    HelperFunctions.getColour(companyName, pageNum, 8);
  });

  function formatDateWithId(date, campaignId) {
    var day = date.getDate().toString().padStart(2, "0");
    var month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}-${campaignId}`;
  }

  function nextClicked() {
    setPageNum(pageNum + 1);
  }

  function prevClicked() {
    setPageNum(pageNum - 1);
  }

  function backClicked() {
    window.location.href = `/${companyName}/reports-list`;
  }

  function getLast5Campaigns(isResend) {
    var campaignsList = [];
    if (isResend === 0) {
      campaignsList = [campaign];
      for (let i = 1; i < 6; i++) {
        let monthlySavedCampaigns = savedCampaigns.filter(
          (x) =>
            new Date(x.sendTime).getMonth() + 1 === months[i] &&
            new Date(x.sendTime).getFullYear() === years[i]
        );
        if (monthlySavedCampaigns.length > 1) {
          campaignsList = campaignsList.concat(monthlySavedCampaigns[1]);
        } else {
          campaignsList = campaignsList.concat(monthlySavedCampaigns);
        }
      }
    } else {
      campaignsList = [
        campaigns.filter(
          (c) =>
            new Date(c.sendTime).getMonth() + 1 ===
              new Date(campaign.sendTime).getMonth() + 1 &&
            new Date(c.sendTime).getFullYear() ===
              new Date(campaign.sendTime).getFullYear() &&
            new Date(c.sendTime).getDate() !==
              new Date(campaign.sendTime).getDate()
        )[0],
      ];
      for (let i = 1; i < 6; i++) {
        let monthlySavedCampaigns = savedCampaigns.filter(
          (x) =>
            new Date(x.sendTime).getMonth() + 1 === months[i] &&
            new Date(x.sendTime).getFullYear() === years[i]
        );
        if (monthlySavedCampaigns.length > 1) {
          campaignsList = campaignsList.concat(monthlySavedCampaigns[0]);
        } else {
          campaignsList = campaignsList.concat(null);
        }
      }
    }
    return campaignsList;
  }

  function getLast5Reports(isResend) {
    var reportsList = [];
    if (isResend === 0) {
      reportsList = [report];
      for (let i = 1; i < 6; i++) {
        let monthlySavedReports = savedReports.filter(
          (x) =>
            new Date(x.sendTime).getMonth() + 1 === months[i] &&
            new Date(x.sendTime).getFullYear() === years[i]
        );
        if (monthlySavedReports.length > 1) {
          reportsList = reportsList.concat(monthlySavedReports[1]);
        } else {
          reportsList = reportsList.concat(monthlySavedReports);
        }
      }
    } else {
      reportsList = [
        reports.filter(
          (r) =>
            new Date(r.sendTime).getMonth() ===
              new Date(report.sendTime).getMonth() &&
            new Date(r.sendTime).getFullYear() ===
              new Date(report.sendTime).getFullYear() &&
            new Date(r.sendTime).getDate() !==
              new Date(report.sendTime).getDate()
        )[0],
      ];
      for (let i = 1; i < 6; i++) {
        let monthlySavedReports = savedReports.filter(
          (x) =>
            new Date(x.sendTime).getMonth() + 1 === months[i] &&
            new Date(x.sendTime).getFullYear() === years[i]
        );
        if (monthlySavedReports.length > 1) {
          reportsList = reportsList.concat(monthlySavedReports[0]);
        } else {
          reportsList = reportsList.concat(null);
        }
      }
    }
    return reportsList;
  }

  async function updateData() {
    if (
      window.confirm(
        "Are you sure you want to update this report?\nThis will overwrite the saved data."
      )
    ) {
      setIsUpdateClicked(true);
      // Save the first send campaign and report
      let [updatedCampaign, updatedReport, updatedLinks, updatedPreview] =
        await HelperFunctions.LoadNewData(campaignId);
      let data = [updatedCampaign, updatedReport, updatedLinks, updatedPreview];
      let url = `https://hitsendreporting.s05.system7.co.nz/api/api/report/save/${companyName}/${formatDateWithId(
        new Date(updatedCampaign.sendTime),
        updatedCampaign.id
      )}`;
      const [error1, result1] = await ReportAPIService.SaveData(url, data);
      if (error1) {
        console.error("Error fetching preview:", error1);
      }
      if (result1) {
        // Save the resend campaign and reload the page
        let [
          updatedResendCampaign,
          updatedResendReport,
          updatedResendLinks,
          updatedResendPreview,
        ] = await HelperFunctions.LoadNewData(campaigns[resendIndex].id);
        data = [
          updatedResendCampaign,
          updatedResendReport,
          updatedResendLinks,
          updatedResendPreview,
        ];
        url = `https://hitsendreporting.s05.system7.co.nz/api/api/report/save/${companyName}/${formatDateWithId(
          new Date(updatedResendCampaign.sendTime),
          updatedResendCampaign.id
        )}`;
        const [error2, result2] = await ReportAPIService.SaveData(url, data);
        if (error2) {
          console.error("Error fetching preview:", error2);
        }
        if (result2) {
          setIsUpdateClicked(false);
          window.location.reload();
        }
      }
    }
  }

  function choosePage() {
    if (pageNum === 1) {
      return (
        <MonthlyPage1
          campaign={campaign}
          monthlyDate={monthlyDate}
          companyName={companyName}
          previewHtml={previewHtml}
          previewRefPage1={previewRefPage1}
        />
      );
    } else if (pageNum === 2) {
      return (
        <MonthlyPage2Cloudbase
          campaign={campaign}
          report={report}
          lastCampaign={savedCampaigns[1]}
          lastReport={savedReports[1]}
          topLinks={topLinks}
        />
      );
    } else if (pageNum === 3) {
      return (
        <MonthlyPage4Cloudbase
          campaigns={getLast5Campaigns(0)}
          months={months}
        />
      );
    } else if (pageNum === 4) {
      return (
        <MonthlyPage5Cloudbase
          campaigns={getLast5Campaigns(0)}
          reports={getLast5Reports(0)}
          months={months}
        />
      );
    } else if (pageNum === 5) {
      return (
        <MonthlyPage1Cloudbase
          campaign={getLast5Campaigns(1)[0]}
          monthlyDate={monthlyDate}
          companyName={companyName}
          previewHtml={previewHtml}
          previewRefPage1={previewRefPage1Cloudbase}
        />
      );
    } else if (pageNum === 6) {
      return (
        <MonthlyPage2Cloudbase
          campaign={getLast5Campaigns(1)[0]}
          report={getLast5Reports(1)[0]}
          lastCampaign={savedCampaigns[0]}
          lastReport={savedReports[0]}
          topLinks={resendTopLinks}
        />
      );
    } else if (pageNum === 7) {
      return (
        <MonthlyPage4Cloudbase
          campaigns={getLast5Campaigns(1)}
          months={months}
        />
      );
    } else if (pageNum === 8) {
      return (
        <MonthlyPage5Cloudbase
          campaigns={getLast5Campaigns(1)}
          reports={getLast5Reports(1)}
          months={months}
        />
      );
    }
    return null;
  }

  function showPages() {
    let page = pageNum;
    if (pageNum >= 5) {
      page -= 4;
    }
    if (page > 2 && page < 5) {
      page++;
    }
    return (
      <>
        <div className="monthly-report" id="monthly-report">
          <div
            className={`monthly-page-${page} monthly-page-${pageNum}-cloudbase`}
            id={`monthly-page-${page}`}
          >
            {choosePage()}
            {pageNum !== 0 && pageNum !== 1 ? (
              <button
                className="btn-general btn-prev"
                onClick={() => prevClicked()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2rem"
                  viewBox="0 0 320 512"
                >
                  <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                </svg>
              </button>
            ) : null}
            {pageNum !== 8 ? (
              <button
                className="btn-general btn-next"
                onClick={() => nextClicked()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2rem"
                  viewBox="0 0 320 512"
                >
                  <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              </button>
            ) : null}
            <button
              className="btn-general btn-export"
              onClick={() => {
                setPageNum(0);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2rem"
                viewBox="0 0 512 512"
              >
                <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
              </svg>
            </button>
          </div>
        </div>
      </>
    );
  }

  function main() {
    if (
      campaign &&
      report &&
      previewHtml &&
      savedCampaigns &&
      savedReports &&
      links.length > 0 &&
      linksList.length > 0 &&
      topLinks.length > 0 &&
      monthlyDate
    ) {
      if (pageNum === 0) {
        return (
          <>
            <div className="monthly-page-0" id="monthly-page-0">
              <button
                className="btn-general btn-next"
                onClick={() => nextClicked()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="3em"
                  viewBox="0 0 320 512"
                >
                  <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              </button>
              <button
                className="btn-general btn-export"
                onClick={() => {
                  setPageNum(0);
                  // HelperFunctions.generatePDF();
                  HelperFunctions.generateImages(companyName);
                  HelperFunctions.generateIframe(companyName, previewHtml);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="2em"
                  viewBox="0 0 512 512"
                >
                  <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                </svg>
              </button>
              <div className="monthly-pages-cloudbase" id="monthly-pages">
                <div className="monthly-report" id="monthly-report">
                  <div
                    className="monthly-page-1 monthly-page-1-cloudbase component-to-capture"
                    id="monthly-page-1"
                  >
                    <MonthlyPage1
                      campaign={campaign}
                      monthlyDate={monthlyDate}
                      companyName={companyName}
                      previewHtml={previewHtml}
                      previewRefPage1={previewRefPage1}
                    />
                  </div>
                </div>
                <div className="monthly-report" id="monthly-report">
                  <div
                    className="monthly-page-2-cloudbase component-to-capture"
                    id="monthly-page-2-cloudbase"
                  >
                    <MonthlyPage2Cloudbase
                      campaign={campaign}
                      report={report}
                      lastCampaign={savedCampaigns[1]}
                      lastReport={savedReports[1]}
                      topLinks={topLinks}
                    />
                  </div>
                </div>
                <div className="monthly-report" id="monthly-report">
                  <div
                    className="monthly-page-4 monthly-page-3-cloudbase component-to-capture"
                    id="monthly-page-4"
                    ref={myDivRef}
                  >
                    <MonthlyPage4Cloudbase
                      campaigns={getLast5Campaigns(0)}
                      months={months}
                    />
                  </div>
                </div>
                <div className="monthly-report" id="monthly-report">
                  <div
                    className="monthly-page-5 monthly-page-4-cloudbase component-to-capture"
                    id="monthly-page-5"
                  >
                    <MonthlyPage5Cloudbase
                      campaigns={getLast5Campaigns(0)}
                      reports={getLast5Reports(0)}
                      months={months}
                    />
                  </div>
                </div>
                <div className="monthly-report" id="monthly-report">
                  <div
                    className="monthly-page-1 monthly-page-5-cloudbase component-to-capture"
                    id="monthly-page-1"
                  >
                    <MonthlyPage1Cloudbase
                      campaign={getLast5Campaigns(1)[0]}
                      monthlyDate={monthlyDate}
                      companyName={companyName}
                      previewHtml={previewHtml}
                      previewRefPage1={previewRefPage1Cloudbase}
                    />
                  </div>
                </div>
                <div className="monthly-report" id="monthly-report">
                  <div
                    className="monthly-page-2-cloudbase monthly-page-6-cloudbase component-to-capture"
                    id="monthly-page-2-cloudbase"
                  >
                    <MonthlyPage2Cloudbase
                      campaign={getLast5Campaigns(1)[0]}
                      report={getLast5Reports(1)[0]}
                      lastCampaign={savedCampaigns[0]}
                      lastReport={savedReports[0]}
                      topLinks={resendTopLinks}
                    />
                  </div>
                </div>
                <div className="monthly-report" id="monthly-report">
                  <div
                    className="monthly-page-4 monthly-page-7-cloudbase component-to-capture"
                    id="monthly-page-4"
                    ref={myDivRef}
                  >
                    <MonthlyPage4Cloudbase
                      campaigns={getLast5Campaigns(1)}
                      months={months}
                    />
                  </div>
                </div>
                <div className="monthly-report" id="monthly-report">
                  <div
                    className="monthly-page-5 monthly-page-8-cloudbase component-to-capture"
                    id="monthly-page-5"
                  >
                    <MonthlyPage5Cloudbase
                      campaigns={getLast5Campaigns(1)}
                      reports={getLast5Reports(1)}
                      months={months}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      } else if (pageNum > 0) {
        return showPages();
      } else {
        return (
          <>
            <div className="monthly-report" id="monthly-report">
              Loading...
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <div className="monthly-report" id="monthly-report">
            Loading...
          </div>
        </>
      );
    }
  }

  return (
    <>
      <button
        className="btn-general btn-back"
        onClick={() => {
          backClicked();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2rem"
          viewBox="0 0 448 512"
        >
          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
        </svg>
      </button>
      <button
        className="btn-general btn-update"
        onClick={() => {
          updateData();
        }}
      >
        {isUpdateClicked ? "Updating..." : "Update"}
      </button>
      {main()}
    </>
  );
}
