import React, { useState, useEffect } from "react";
import HitSendLogo from "../images/HitSend-logo.png";
import ReportAPIService from "../api/reports.api.js";
import HelperFunctions from "../HelperFunctions/HelperFunctions.js";
import FormatNumbersFunctions from "../HelperFunctions/FormatNumbersFunctions.js";

export default function ReportsList() {
  const companyName = window.location.href.split("//").pop().split("/")[1];
  const [campaigns, setCampaigns] = useState([]);
  const [reports, setReports] = useState([]);
  const [lastModifiedDates, setLastModifiedDates] = useState([]);

  useEffect(() => {
    async function getContentFromSavedFile(returnedData) {
      var tempCampaigns = [];
      var tempReports = [];
      var tempLastModifiedDates = [];

      for (const element of returnedData) {
        const [errorContent, content] =
          await ReportAPIService.GetSavedFileContent(companyName, element);
        if (errorContent) {
          console.error("Error fetching saved content:", errorContent);
        }
        if (content[0] && content[1]) {
          tempCampaigns = tempCampaigns.concat(content[0]);
          tempReports = tempReports.concat(content[1]);
        }

        const [errorLastModifiedDate, lastModifiedDate] =
          await ReportAPIService.GetLastModifiedDate(companyName, element);
        if (errorLastModifiedDate) {
          console.error(
            "Error fetching last modified date:",
            errorLastModifiedDate
          );
        }
        if (lastModifiedDate) {
          tempLastModifiedDates =
            tempLastModifiedDates.concat(lastModifiedDate);
        }
      }
      tempCampaigns.sort((a, b) => new Date(b.sendTime) - new Date(a.sendTime));
      tempReports.sort((a, b) => new Date(b.sendTime) - new Date(a.sendTime));
      tempLastModifiedDates.reverse();
      console.log(tempCampaigns)
      setCampaigns(tempCampaigns);
      setReports(tempReports);
      setLastModifiedDates(tempLastModifiedDates);
    }

    async function getSavedFiles() {
      const [errorFileNames, fileNames] =
        await ReportAPIService.GetSavedFileNames(companyName);
      if (errorFileNames) {
        console.error("Error fetching campaigns:", errorFileNames);
      }
      if (fileNames) {
        fileNames.forEach((element, index, array) => {
          array[index] = element.split("\\").pop();
        });
        return fileNames;
      }
      return null;
    }

    async function loadSavedData() {
      const returnedData = await getSavedFiles();
      if (campaigns.length === 0) {
        await getContentFromSavedFile(returnedData);
      }
    }

    loadSavedData();
  });

  async function fetchLiveData() {
    await HelperFunctions.fetchLiveData(companyName, campaigns);
  }

  function backClicked() {
    window.location.href = `/`;
  }

  function showTableRows(campaign, index) {
    console.log(campaign.sendTime)
    return (
      <tr key={index}>
        <td className="nowrap">
          {!(companyName.includes("workshop-7") || companyName.includes("onpoint")) ? 
          FormatNumbersFunctions.formatDate(new Date(campaign.sendTime)) : 
          FormatNumbersFunctions.formatDateWithTime(new Date(campaign.sendTime))}
        </td>
        <td>
          <a
            href={!(companyName.includes("workshop-7") || companyName.includes("onpoint")) ? 
              `/${companyName}/${FormatNumbersFunctions.formatDateForFileName(
              new Date(campaigns[index].sendTime)
            )}/${campaigns[index].id}` : 
          `/${companyName}/${FormatNumbersFunctions.formatDateWithTime(
              new Date(campaigns[index].sendTime)
            )}/${campaigns[index].id}`}
          >
            {campaign.settings.subjectLine}
          </a>
          <br />
          <span style={{ fontSize: "0.9rem" }}>
            {campaign.settings.previewText}
          </span>
        </td>
        <td className="text-right">
          {FormatNumbersFunctions.formatNumber(
            campaign.recipients.recipientCount
          )}
        </td>
        <td className="text-right">
          {FormatNumbersFunctions.formatNumber(
            campaign.recipients.recipientCount -
              (reports[index].bounces.hardBounces +
                reports[index].bounces.softBounces)
          )}
        </td>
        <td className="text-right">{reports[index].unsubscribed}</td>
        <td className="text-right">
          {FormatNumbersFunctions.formatPercentage(
            campaign.reportSummary.openRate
          )}
          %
        </td>
        <td className="text-right">
          {FormatNumbersFunctions.formatNumber(campaign.reportSummary.opens)}
        </td>
        <td className="text-right">
          {FormatNumbersFunctions.formatPercentage(
            reports[index].clicks.uniqueSubscriberClicks /
              reports[index].opens.uniqueOpens
          )}
          %
        </td>
        <td className="text-right">
          {FormatNumbersFunctions.formatNumber(campaign.reportSummary.clicks)}
        </td>
        <td className="update-col">{lastModifiedDates[index]}</td>
      </tr>
    );
  }

  function showCampaigns() {
    if (campaigns) {
      return campaigns.map((campaign, index) => {
        // if (
        //   campaigns[index + 1] &&
        //   FormatNumbersFunctions.formatDate(
        //     new Date(campaigns[index + 1].sendTime)
        //   ) !==
        //     FormatNumbersFunctions.formatDate(
        //       new Date(campaigns[index].sendTime)
        //     )
        // ) {
          return showTableRows(campaign, index);
        // }
        // return null;
      });
    }
    return (
      <tr>
        <td colSpan="11">No Report</td>
      </tr>
    );
  }

  function main() {
    return (
      <>
        <button
          className="btn-general btn-back"
          onClick={() => {
            backClicked();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="2em"
            viewBox="0 0 448 512"
          >
            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
          </svg>
        </button>
        <div className="App">
          <div className="container">
            <div className="reports">
              <div className="row" style={{ marginTop: "150px" }}></div>
              <div className="row">
                <h1>
                  {companyName.split("-").join(" ").toUpperCase()}
                  <br />
                  Monthly Report
                </h1>
              </div>
              <div className="row" style={{ marginTop: "50px" }}></div>
              <div className="row">
                <table className="dataTable">
                  <thead>
                    <tr>
                      <th>Sent Date</th>
                      <th>Subject & Preview Text</th>
                      <th className="text-right">Recipients</th>
                      <th className="text-right" style={{ width: "5%" }}>
                        Sent
                      </th>
                      <th className="text-right">Unsubscribes</th>
                      <th className="text-right">Open Rate</th>
                      <th className="text-right">Views</th>
                      <th className="text-right">Clicks per Unique Open</th>
                      <th className="text-right">Clicks</th>
                      <th className="update-col">Last Updated</th>
                    </tr>
                  </thead>
                  <tbody>{showCampaigns()}</tbody>
                </table>
                <button
                  className="btn-general btn-load"
                  onClick={() => {
                    fetchLiveData();
                  }}
                >
                  Load New Newsletters from Mailchimp
                </button>
              </div>
            </div>
            <img
              className="hitsend-logo-home"
              src={HitSendLogo}
              alt="HitSend Logo"
            />
          </div>
        </div>
      </>
    );
  }

  return <>{main()}</>;
}
