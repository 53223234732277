import React from "react";
import HitSendLogo from "./images/HitSend-logo.png";

export default function Home() {
  const companies = [
    { Name: "System 7", Code: "system7" },
    { Name: "Bug King", Code: "bug-king" },

    { Name: "Angel Property Managers", Code: "angel-property-managers" },
    {
      Name: "Cloudbase Print And Promotion",
      Code: "cloudbase-print-and-promotion",
    },
    { Name: "GreenSide Energy Solutions", Code: "greenside-energy-solutions" },
    {
      Name: "Metalcraft Insulated Panels",
      Code: "metalcraft-insulated-panels",
    },
    { Name: "Workshop 7", Code: "workshop-7" },
    { Name: "CrediFlex", Code: "crediflex" },
    { Name: "Mat Wood", Code: "mat-wood" },
    { Name: "MTP", Code: "mtp" },
    { Name: "Vita Pacific", Code: "vita-pacific" },
    { Name: "Contact Lens Express", Code: "contact-lens-express" },
    { Name: "Scafman", Code: "scafman" },
    { Name: "Leck Electrical", Code: "leck-electrical" },
    { Name: "David Kerr", Code: "david-kerr" },
    { Name: "OnPoint Financial Advice", Code: "onpoint-financial-advice" },
    { Name: "Koru Staging", Code: "koru-staging" },
  ];

  function main() {
    return (
      <>
        <div className="reports">
          <div className="row" style={{ marginTop: "150px" }}>
            <h1>Company Reports</h1>
          </div>
          <div className="row" style={{ marginTop: "50px" }}>
            <table className="dataTable">
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Reports List</th>
                  <th>Yearly Report</th>
                </tr>
              </thead>
              <tbody>
                {companies.map((company) => (
                  <tr key={company.Code}>
                    <td>{company.Name}</td>
                    <td>
                      <a href={`/${company.Code}/reports-list`}>
                        Monthly Reports
                      </a>
                    </td>
                    <td>
                      <a href={`/${company.Code}/yearly-report`}>
                        Yearly Reports
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <img
            className="hitsend-logo-home"
            src={HitSendLogo}
            alt="HitSend Logo"
          />
        </div>
      </>
    );
  }

  return <>{main()}</>;
}
