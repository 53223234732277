import React, { useState, useEffect } from "react";
import HitSendLogo from "../images/HitSend-logo.png";
import BugKingLogo from "../images/Bug-King-Logo.jpg";
import AngelPMLogo from "../images/angel-property-managers-logo.png";
import GreenSideLogo from "../images/GreenSide_tagline_logo_V2.png";
import CloudbaseLogo from "../images/Cloudbase-logo.png";
import CrediflexLogo from "../images/Crediflex_Logo.png";
import CLELogo from "../images/CLE.png";
import Workshop7Logo from "../images/Workshop7Logo.png";
import ScafmanLogo from "../images/scafman.png";
import LeckElectricalLogo from "../images/LeckElectricalLogo.png";
import DavidKerrLogo from "../images/DavidKerrLogo.png";
import System7Logo from "../images/system7-logo.png";
import VitaPacificLogo from "../images/vita-pacific-logo.png";
import MTPLogo from "../images/MTPLogo.png";
import OnPointLogo from "../images/OnPointLogo.png";
import PhoneImage from "../images/phone.png";

export default function MonthlyPage1Cloudbase({
  campaign,
  monthlyDate,
  companyName,
  previewHtml,
  previewRefPage1,
  phoneImageRef,
}) {
  const [logo, setLogo] = useState();
  const date = new Date(
    campaign.sendTime !== null && campaign.sendTime !== undefined
      ? campaign.sendTime
      : new Date()
  );

  useEffect(() => {
    if (previewRefPage1.current) {
      previewRefPage1.current.innerHTML = previewHtml;
    }
  }, [previewRefPage1, previewHtml]);

  function getLogo() {
    if (companyName === "bug-king") setLogo(BugKingLogo);
    else if (companyName === "angel-property-managers") setLogo(AngelPMLogo);
    else if (companyName === "cloudbase-print-and-promotion")
      setLogo(CloudbaseLogo);
    else if (companyName === "greenside-energy-solutions")
      setLogo(GreenSideLogo);
    else if (companyName === "metalcraft-insulated-panels")
      setLogo(BugKingLogo);
    else if (companyName === "workshop-7") setLogo(Workshop7Logo);
    else if (companyName === "crediflex") setLogo(CrediflexLogo);
    else if (companyName === "mat-wood") setLogo(CrediflexLogo);
    else if (companyName === "contact-lens-express") setLogo(CLELogo);
    else if (companyName === "scafman") setLogo(ScafmanLogo);
    else if (companyName === "leck-electrical") setLogo(LeckElectricalLogo);
    else if (companyName === "david-kerr") setLogo(DavidKerrLogo);
    else if (companyName === "mtp") setLogo(MTPLogo);
    else if (companyName === "system7") setLogo(System7Logo);
    else if (companyName === "vita-pacific") setLogo(VitaPacificLogo);
    else if (companyName === "onpoint-financial-advice") setLogo(OnPointLogo);
  }

  function changeDate(date) {
    if (logo === null || logo === undefined) {
      getLogo();
    }
    const day = date.getDate();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][date.getMonth()];
    const year = date.getFullYear();

    let dayString = day.toString();
    let suffix = "";

    if (
      dayString.length === 1 ||
      (dayString.length > 1 && dayString.charAt(dayString.length - 2) !== "1")
    ) {
      switch (dayString.charAt(dayString.length - 1)) {
        case "1":
          suffix = "st";
          break;
        case "2":
          suffix = "nd";
          break;
        case "3":
          suffix = "rd";
          break;
        default:
          suffix = "th";
          break;
      }
    } else {
      suffix = "th";
    }

    const formattedDate = `${day}${suffix} ${month} ${year}`;
    return formattedDate;
  }

  function main() {
    return (
      <>
        <div className="row content">
          <div className="col-md-6 left-side">
            <div className="row title">
              <div>
                <h1
                  className="heading"
                  style={{
                    fontSize: "5.4vh",
                  }}
                >
                  NEWSLETTER
                </h1>
              </div>
              <div>
                <h1
                  className="heading"
                  style={{
                    fontSize: "5.4vh",
                  }}
                >
                  REPORT
                </h1>
              </div>
              <br />
              <img
                src={logo}
                className={companyName === "system7" ? "system7-logo" : ""}
                alt="Client Logo"
                style={{
                  width: "auto",
                  height: "auto",
                  maxWidth: "50vh",
                  minHeight: "7vh",
                  maxHeight: "15vh",
                }}
              />
            </div>
            <div className="sub-title">
              <div className="heading-subheading">
                <h4 className="heading">Campaign: </h4>
                <h4 className="sub-heading">
                  {campaign.settings.title !== null &&
                  campaign.settings.title !== undefined
                    ? campaign.settings.title
                    : ""}
                </h4>
              </div>
              <div className="heading-subheading">
                <h4 className="heading">Audience: </h4>
                <h4 className="sub-heading">
                  {campaign.recipients.listName !== null &&
                  campaign.recipients.listName !== undefined
                    ? campaign.recipients.listName
                    : ""}
                </h4>
              </div>
              <div
                className="heading-subheading"
                style={{ marginBottom: "9.7vh" }}
              >
                <h4 className="heading">Delivery Date: </h4>
                <h4 className="sub-heading">{changeDate(date)}</h4>
              </div>
              <h4 className="heading">
                Report prepared for{" "}
                {campaign.settings.fromName !== null &&
                campaign.settings.fromName !== undefined
                  ? campaign.settings.fromName
                  : ""}
                , {changeDate(monthlyDate)}
              </h4>
            </div>
          </div>
          <div className="col-md-6 right-side">
            <div className="previewHtml">
              <img
                ref={phoneImageRef}
                src={PhoneImage}
                alt="Phone"
                style={{
                  width: "19vw",
                  height: "100%",
                  position: "absolute",
                  top: "0",
                  right: "0",
                }}
              />
              <div className="campaign-preview" id="campaign-preview">
                <div ref={previewRefPage1} id="campaign-preview-id" />
              </div>
            </div>
            <div className="circle"></div>
          </div>
        </div>
        <img className="hitsend-logo " src={HitSendLogo} alt="HitSend Logo" />
      </>
    );
  }

  return <>{main()}</>;
}
